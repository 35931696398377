import React, { useEffect } from "react";
import CloseIcon from "../../assets/close.svg";
import style from "./CustomModal.module.scss";

const CustomModal = ({
  children,
  maxWidth = "600px",
  onClose,
  modalVisible,
}) => {
  useEffect(() => {
    const body = document.body;
    if (modalVisible) body.classList.add("isHidden");
    return () => {
      body.classList.remove("isHidden");
    };
  }, [modalVisible]);

  return (
    <div className={`${style.customModal} ${modalVisible ? style.active : ""}`}>
      <div
        className={style.customModal__backdrop}
        onClick={() => onClose(false)}
      ></div>
      <div
        className={style.customModal__content}
        style={{ maxWidth: maxWidth }}
      >
        <img
          className={style.customModal__close}
          src={CloseIcon}
          onClick={() => onClose(false)}
          alt="Close"
        />

        <div className={style.customModal__inner}>{children}</div>
      </div>
    </div>
  );
};
export default CustomModal;
