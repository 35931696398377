import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import { fetchFiles } from "../../../redux/sagas/recordFile/fetchRecordFilesSaga";
import { useRecordFiles } from "../../../redux/reducers/RecordFileReducer";
import AttachmentContainer from "../../../components/AttachmentContainer";
import CSVDownloadBtn from "../../../components/CSVDownloadBtn";
import "./Upload.scss";
import { useDispatchEffect } from "../../../utils/hooks";
import { BASE_URL } from "../../../utils/CONSTANTS";
import moment from "moment";

import { FaTrash } from "react-icons/fa";
import swal from "sweetalert";
import { deleteFile } from "../../../redux/sagas/recordFile/deleteRecordFileSaga";
import pdf from "../../../assets/pdf.svg";
import upload from "../../../assets/upload.svg";

const Upload = () => {
  const navigate = useNavigate();
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();

  const refreshFiles = useDispatchEffect(fetchFiles, null, true);
  const recordFiles = useRecordFiles();

  const confirmFileDelete = (fileName, id) => {
    swal({
      title: "Delete this file?",
      text: fileName,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          deleteFile({
            id,
            refreshFiles,
          })
        );
      }
    });
  };

  const ToDashboard = (type) => {
    let navigationParams = {};
    if (type) {
      navigationParams = {
        ...navigationParams,
        replace: true,
        state: { data: { type } },
      };
    }
    navigate("/student/dashboard", navigationParams);
  };

  const handleRedirectMFVF = () => {
    window.open(
      "https://www.bacb.com/wp-content/uploads/2022/01/BACB-Monthly-Fieldwork-Verification-Form-Individual_2022_02_24.pdf",
      "_blank"
    );
  };

  const handleRedirectFFVF = () => {
    window.open(
      "https://www.bacb.com/wp-content/uploads/2022/01/BACB-Final-Fieldwork-Verification-Form-Individual_221013.pdf",
      "_blank"
    );
  };

  const handleRedirectMFVFO = () => {
    window.open(
      "https://www.bacb.com/wp-content/uploads/2022/01/BACB-Monthly-Fieldwork-Verification-Form-Organization_2022_02_24.pdf",
      "_blank"
    );
  };

  const handleRedirectFFVFO = () => {
    window.open(
      "https://www.bacb.com/wp-content/uploads/2022/01/BACB-Final-Fieldwork-Verification-Form-Organization_221031-2.pdf",
      "_blank"
    );
  };

  return (
    <div className="section has-light-background">
      <div className="fieldwor-froms">
        <div className="columns">
          <div className="column"></div>
        </div>
        <div className="columns">
          <div className="column">
            <button
              className="Toggle_btn"
              onClick={() => dispatch(setShowSidebar(!showSidebar))}
            >
              {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
            </button>
            <h1 className="headingMain" style={{ margin: 0 }}>
              Fieldwork Forms
            </h1>

            <p className="pera mt30">
              Supervisors and trainees are required to use two forms to verify
              fieldwork.
              <b>
                Both parties must retain a copy of the completed fieldwork forms
                for at least seven years.
              </b>
            </p>

            <h3 className="mt30">
              Monthly Fieldwork Verification Form (M-FVF)
            </h3>
            <p>
              <b>
                This form must be signed by the last day of the calendar month
                following the month of supervision.
              </b>{" "}
              You are required to submit this form to the BACB upon request.
            </p>
            <a
              className="button teal-button mt20 pera"
              onClick={() => ToDashboard()}
            >
              <img src={upload} />
              Upload your M-FVF here
            </a>

            <h3 className="mt25">Final Fieldwork Verification Form (F-FVF)</h3>
            <p>
              <b>
                We highly recommend that the F-FVF be signed at the end of a
                specific fieldwork experience.
              </b>{" "}
              These forms will be submitted in the certification process.
            </p>
            <a
              className="button teal-button mt20 pera"
              onClick={() => ToDashboard("F-FVF")}
            >
              <img src={upload} />
              Upload your F-FVF here
            </a>

            <h3 className="mt25">
              <strong>Individual Supervisor Forms</strong>
            </h3>

            <ul className="pera mt15 list-flex">
              <li className="a_style_download mr15">
                <button
                  className="a_style_download mr15"
                  onClick={handleRedirectMFVF}
                >
                  <img src={pdf} />
                  Monthly Fieldwork Verification Form
                </button>
              </li>
              <li className="a_style_download">
                <button
                  onClick={handleRedirectFFVF}
                  className="a_style_download mr15"
                >
                  <img src={pdf} />
                  Final Fieldwork Verification Form
                </button>
              </li>
            </ul>

            <h3 className="mt15">
              <strong>Organization Forms</strong>
            </h3>

            <ul className="pera mt15 list-flex">
              <li className="a_style_download mr15">
                <button
                  className="a_style_download mr15"
                  onClick={handleRedirectMFVFO}
                >
                  <img src={pdf} />
                  Monthly Fieldwork Verification Form
                </button>
              </li>
              <li className="a_style_download">
                <button
                  className="a_style_download mr15"
                  onClick={handleRedirectFFVFO}
                >
                  <img src={pdf} />
                  Final Fieldwork Verification Form
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
