import React, { useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack } from "react-icons/io";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import Rec1 from "../../../assets/Rectangle 22.png";
import Rec2 from "../../../assets/Rectangle 23.png";
import Rec3 from "../../../assets/Rectangle 24.png";
import CardPaymentForm from "../../../components/CardPaymentForm";
import {
  useCouponDetails,
  usePaymentSuccess,
  useSuccess,
} from "../../../redux/reducers/SubscriptionReducer";
import {
  useData,
  useUserSubscription,
} from "../../../redux/reducers/SubscriptionReducer";
import {
  addSubscription,
  updateSubscription,
} from "../../../redux/sagas/subscription/paymentSaga";
import { useDispatchEffect, useQuery } from "../../../utils/hooks";
import "./paymentdashboardSubscription.scss";
import { fetchSubscriptions } from "../../../redux/sagas/subscription/fetchSaga";

const PaymentDashboardScreen = () => {
  const navigate = useNavigate();
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();
  const subscriptions = useData();
  const userSubscription = useUserSubscription();
  const isSubscribed = useSuccess();
  const paymentSuccess = usePaymentSuccess();
  const couponDetails = useCouponDetails();

  useDispatchEffect(fetchSubscriptions, null, subscriptions?.length == 0);

  const query = useQuery();
  const subscription = query.get("subscription");
  const selected = subscriptions.find(
    (item) => item.id == Number(subscription)
  );

  useEffect(() => {
    if (paymentSuccess) {
      navigate(`/student/dashboard-subscription`);
    }
  }, [paymentSuccess]);

  const onTokenGenerated = ({ token, couponCode }) => {
    const params = {
      sub_id: Number(subscription),
      token: token,
      coupon: couponCode,
    };
    if (userSubscription && userSubscription.stripe_subscription_id)
      dispatch(updateSubscription(params));
    else dispatch(addSubscription(params));
  };
  console.log("-------------- Amount to be paid:", couponDetails);
  let disc = couponDetails?.percent_off
    ? (selected.price?.price * couponDetails?.percent_off) / 100
    : couponDetails?.amount_off / 100;
  return (
    <div className="section has-light-background">
      <div className="columns">
        <div className="column"></div>
      </div>
      <div className="columns">
        <div className="column is-two-thirds">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          <p className="headingMain">
            <a className=" backlink" onClick={(_) => navigate(-1)}>
              <IoIosArrowBack />
            </a>
            Payment
          </p>
          <p className="pera offer-title mt60">Amount to be paid:</p>
          {couponDetails?.valid ? (
            <div className={`offer-info`}>
              <p className={`offer-info-price heading2nd mt30`}>
                $
                {disc > selected.price?.price
                  ? "0"
                  : selected.price?.price - disc}
              </p>
              <p className="offer-info-old heading3rd mt30">
                <s>${selected.price?.price}</s>
              </p>
              <p className="offer-info-off heading3rd mt30">
                {disc > selected.price?.price
                  ? "100"
                  : ((disc / selected.price?.price) * 100)?.toFixed(2)}
                % off
              </p>
            </div>
          ) : (
            <>
              <p className="heading2nd mt30">${selected.price?.price}</p>
            </>
          )}
          <div className="mt30">
            <img src={Rec1} alt="Melton Hill Lake" />
            <img style={{ marginLeft: 20 }} src={Rec2} alt="Melton Hill Lake" />
            <img style={{ marginLeft: 20 }} src={Rec3} alt="Melton Hill Lake" />
          </div>
          <div className="columns  mt30">
            <div className="column is-two-fifths">
              <label className="label ml0">Card Details:</label>
            </div>
          </div>
          <CardPaymentForm onSuccess={onTokenGenerated} />
        </div>
      </div>
    </div>
  );
};

export default PaymentDashboardScreen;
