import "react-pro-sidebar/dist/css/styles.css";

import { BsCheck } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";
import { BsPencil } from "react-icons/bs";
import { useDispatch } from "react-redux";
import "./index.scss";

import React, { useState, useEffect } from "react";

import Backdrop from "@mui/material/Backdrop";
import { useFormik } from "formik";

import { useDispatchEffect } from "../../../utils/hooks";
import {
  useSupervisors,
  useSuccess,
  setSuccess,
} from "../../../redux/reducers/ProfileReducer";
import {
  deleteTraineeSupervisor,
  fetchSupervisors,
  submitTraineeSupervisor,
  updateTraineeSupervisor,
} from "../../../redux/sagas/profile/supervisorSaga";
import { sendInvitation } from "../../../redux/sagas/profile/sendInvitationSaga";

const InviteSupervisor = () => {
  const dispatch = useDispatch();

  const [addSupervisorDialog, setAddSupervisorDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [sendInvitationDialog, setSendInvitationDialog] = useState(false);
  const [selectedSupervisor, setSelectedSupervisor] = useState(false);

  const supervisorList = useSupervisors();

  const refresh = useDispatchEffect(fetchSupervisors, null, true);

  const openEdit = (supervisor) => {
    setAddSupervisorDialog(true);
    setIsEdit(true);
    setSelectedSupervisor(supervisor);
  };

  const closeDialog = () => {
    setAddSupervisorDialog(false);
    setIsEdit(false);
  };

  const openInvitationDialog = (supervisor) => {
    setSendInvitationDialog(true);
    setSelectedSupervisor(supervisor);
  };

  const closeSendInvitationDialog = (supervisor) => {
    setSendInvitationDialog(false);
    setSelectedSupervisor(supervisor);
  };

  return (
    <div>
      <div className="columns mt60">
        <div className="column is-two-thirds ">
          <div className="add-supervisor-header">
            <p className={"heading3rd"}>Supervisors</p>
            <button
              className="button  is-rounded add-supervisor-btn"
              onClick={() => setAddSupervisorDialog(true)}
            >
              Add Supervisor
            </button>
          </div>
          <div className=" mt20" style={{ padding: 0 }}>
            <table className="Flagtable AddSupervisorTable">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email Address</th>
                <th>Tracker Account</th>
              </tr>
              {supervisorList.map((supervisor) => (
                <tr key={supervisor.id}>
                  <td>
                    <button
                      className="edit-button"
                      onClick={() => openEdit(supervisor)}
                    >
                      <BsPencil></BsPencil>
                    </button>
                  </td>
                  <td>
                    {supervisor.name} {supervisor.lastname}
                  </td>
                  <td>{supervisor.email}</td>
                  <td className="trackerAccount">
                    {supervisor.supervisor ? (
                      <BsCheck size="2em"></BsCheck>
                    ) : (
                      <button
                        className="add-supervisor-btn-invite"
                        onClick={() => openInvitationDialog(supervisor)}
                      >
                        <RiCloseFill size="2em"></RiCloseFill>
                        <p>Invite to tracker</p>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </table>
            {supervisorList.length === 0 && (
              <div className="empty-list">No Supervisors found</div>
            )}
          </div>
        </div>
        <AddSupervisor
          isVisible={addSupervisorDialog}
          closeDialog={closeDialog}
          isEdit={isEdit}
          supervisor={selectedSupervisor}
          refresh={refresh}
          addSupervisorDialog={addSupervisorDialog}
          setSelectedSupervisor={setSelectedSupervisor}
        ></AddSupervisor>
        <SendInvitationSupervisor
          isVisible={sendInvitationDialog}
          closeDialog={closeSendInvitationDialog}
          supervisor={selectedSupervisor}
          refresh={refresh}
        ></SendInvitationSupervisor>
      </div>
    </div>
  );
};

const AddSupervisor = ({
  isVisible,
  closeDialog,
  isEdit,
  supervisor,
  refresh,
  addSupervisorDialog,
  setSelectedSupervisor,
}) => {
  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "⋆Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email format";
    }

    if (!values.name) {
      errors.name = "⋆Required";
    }
    return errors;
  };

  const initialValues = {
    name: supervisor?.name || "",
    lastname: supervisor?.lastname || "",
    email: supervisor?.email || "",
    deleteMode: 0,
  };

  const dispatch = useDispatch();

  const onSubmit = (values, onSubmitProps) => {
    const form = {
      name: values.name,
      lastname: values.lastname,
      email: values.email,
    };

    const payload = {
      form,
      refresh,
    };

    if (values.deleteMode) {
      payload["id"] = supervisor.id;
      dispatch(deleteTraineeSupervisor(payload));
      return;
    }

    if (!isEdit) {
      dispatch(submitTraineeSupervisor(payload));
    } else {
      payload["id"] = supervisor.id;
      dispatch(updateTraineeSupervisor(payload));
    }
  };

  const success = useSuccess();

  useEffect(() => {
    if (success) {
      dispatch(setSuccess(false));
      closeDialog();
    }
  }, [success]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!addSupervisorDialog) {
      formik.resetForm();
      setSelectedSupervisor(false);
    }
  }, [addSupervisorDialog]);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isVisible}
      onClick={() => {}}
      style={{ backdropFilter: "blur(1px)" }}
    >
      <div className="supervisor-dialog">
        <div>
          <button className=" close-btn" onClick={closeDialog}>
            <RiCloseFill size="1.5em"></RiCloseFill>
          </button>
        </div>
        <h1>{!isEdit ? "Add Supervisor" : "Edit Supervisor"}</h1>

        <form
          onSubmit={formik.handleSubmit}
          className="mt30 add-supervisor-form"
        >
          <div className="columns form-layout InputSize">
            <div className="column">
              <label className="label">Full Name</label>
              <input
                className="input has-input-background"
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="Register__error">
                {(formik.touched.name && formik.errors.name) || ""}
              </span>
            </div>
          </div>
          <div className="columns form-layout InputSize">
            <div className="column">
              <label className="label">Email</label>
              <input
                className="input has-input-background"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={supervisor?.supervisor != null}
              />
              <span className="Register__error">
                {(formik.touched.email && formik.errors.email) || ""}
              </span>
            </div>
          </div>
          <div className=" form-layout  ">
            <div className="mt30">
              <button
                className="button is-large is-rounded signin-button"
                disabled={!formik.isValid}
                type="submit"
              >
                Save
              </button>
            </div>
            <div className="mt30">
              <button
                className="button is-large is-rounded back-button"
                onClick={() => closeDialog()}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
          {isEdit && (
            <div className="form-layout justify-end ">
              <button
                className="add-supervisor-btn-delete"
                onClick={() => formik.setFieldValue("deleteMode", 1)}
                type="submit"
              >
                Delete supervisor
              </button>
            </div>
          )}
        </form>
      </div>
    </Backdrop>
  );
};

const SendInvitationSupervisor = ({ isVisible, closeDialog, supervisor }) => {
  const dispatch = useDispatch();

  const onSendInvitation = () => {
    const payload = {
      form: {
        supervisor_email: supervisor.email,
      },
    };

    dispatch(sendInvitation(payload));
  };

  const success = useSuccess();

  useEffect(() => {
    if (success) {
      dispatch(setSuccess(false));
      closeDialog();
    }
  }, [success]);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isVisible}
      onClick={() => {}}
      style={{ backdropFilter: "blur(1px)" }}
    >
      <div className="supervisor-dialog">
        <div>
          <button className=" close-btn" onClick={closeDialog}>
            <RiCloseFill size="1.5em"></RiCloseFill>
          </button>
        </div>
        <h1>Invite Supervisor to Tracker!</h1>
        <p className="mt30 send-invitation-msg">
          An invitation email will be sent to <br />
          {supervisor?.email}.
        </p>

        <div className=" form-layout  ">
          <div className="mt30">
            <button
              className="button is-large is-rounded signin-button"
              onClick={onSendInvitation}
            >
              Send Invitation
            </button>
          </div>
          <div className="mt30">
            <button
              className="button is-large is-rounded back-button"
              onClick={() => closeDialog()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};
export default InviteSupervisor;
