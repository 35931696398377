export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const COLORS = {
  black: "#212429",
  dark: "#2A2D32",
  primary: "var(--color-info-dark)",
  light: "rgba(256,256,256,0.498)",
  accent1: "var(--color-warning-dark)",
  accent2: "var(--color-danger)",
  muted: "rgba(255, 255, 255, 0.4)",
};

export const colorPalate = ["var(--color-danger)", "var(--color-warning-dark)", "var(--color-info-dark)"];

export const priorityOptions = [
  {
    value: 0,
    label: " Lowest Priority",
    color: "blue",
  },
  {
    value: 1,
    label: "Medium Priority",
    color: "green",
  },
  {
    value: 2,
    label: "Needs Attention",
    color: "yellow",
  },
  {
    value: 3,
    label: "High Priority",
    color: "orange",
  },
  {
    value: 4,
    label: "Urgent",
    color: "red",
  },
];
export const productOptions = [
  {
    value: "patch",
    label: "patch",
  },
  {
    value: "beta",
    label: "beta",
  },
  {
    value: "group",
    label: "group",
  },
];
export const reasonOptions = [
  {
    value: 0,
    label: "Missed from count",
  },
  {
    value: 1,
    label: "Plotting Error",
  },
  {
    value: 2,
    label: "Plotting Error",
  },
  {
    value: 3,
    label: "Mis-Pressed",
  },
  {
    value: 4,
    label: "Sewing Error",
  },
  {
    value: 5,
    label: "Trimming Error",
  },
];

export const recutStatusOptions = [
  {
    value: 0,
    label: "Submitted - Waiting to be Plotted",
  },
  {
    value: 1,
    label: "Plotted - Waiting to be Pressed",
  },
  {
    value: 2,
    label: "Pressed - Waiting to be Sewn",
  },
  {
    value: 3,
    label: "Sewn - Waiting to be Trimmed",
  },
  {
    value: 4,
    label: "Trimmed - Waiting to be Shipped",
  },
  {
    value: 5,
    label: "Shipped",
  },
];

export const Days = [
  {
    value: "Monday",
    label: "Mon",
  },
  {
    value: "Tuesday",
    label: "Tue",
  },
  {
    value: "Wednesday",
    label: "Wed",
  },
  {
    value: "Thursday",
    label: "Thu",
  },
  {
    value: "Friday",
    label: "Fri",
  },
  {
    value: "Saturday",
    label: "Sat",
  },
  {
    value: "Sunday",
    label: "Sun",
  },
];

export const SM = "SM";
export const MD = "MD";
export const LG = "LG";
export const XL = "XL";

export const AUTH_TOKEN = "@autism-app:token";
export const USER_DATA = "@autism-app:userdata";
export const LOG_DATA = "@autism-app:logdata";

export const LABELS = {
  expereince_type: "Experience Type",
  setting: "Setting",
  supervisor: "Supervisor",
  date_of_experience: "Date of Experience",
  // time_of_experience: "Time of Experience",
  time_of_expereince_start: "Time of Experience Start",
  time_of_expereince_end: "Time of Experience End",
  unrestricted_hours: "Unrestricted Hours",
  restricted_hours: "Restricted Hours",
  experience_hours: "Experience Hours",
  individual_or_group: "Individual or group supervision",
  method_of_supervision: "Method of Supervision",
  supervision_start_time: "Supervision Start Time",
  supervision_end_time: "Supervision End Time",
  supervised_hours: "Supervised Hours",
  independant_hours: "Independent Hours",
  client_observation: "Client Observation",
  supervision_contact: "Supervision contacts",
  task: "Task list items documented",
  experience_note: "Experience Notes",
};

export const TERMS = "Terms of Service";
export const PRIVACY = "Privacy Policy";

export const EXPERIENCE_TYPE_HELP =
  "Hey boo! What type of supervision are you getting? \n\r\n\rHere’s a recap to help: \n\r\n\rSupervised needs 2,000 hours babe. \n\r\n\rConcentrated is hyperspeed with more contact. This version needs 1,500 hours and uses a 1.33 multiplier. \n\r\n\rStill unsure? No sweat love- ask your supervisor!";
export const SETTING_HELP =
  "Where are you recording hours? Are you in a clinic, home, school or community? #environment";
export const SUPERVISOR_HELP = "Who you asking for that John Hancock?";
export const DATE_OF_EXPERIENCE_HELP = "What day did you do this work boo?";
export const TIME_IN_HELP = "What time did this instance of experience start?";
export const TIME_OUT_HELP = "What time did this instance of experience end?";
export const UNRESTRICTED_HELP =
  "WTF is unrestricted? Pretty much any activity that is away from direct work with clients and is behavior analytic in nature. \n\r\n\rThese things are 99% of what you will do in the future as a BCBA. \n\r\n\rExamples: observation, training, data collection, conducting assessments, writing, graphing, research etc. \n\r\n\rNon-examples: school stuff, administrative tasks, non-behavioral tasks, crisis management";
export const RESTRICTED_HELP =
  "WTF is restricted? Any activity that involves the direct delivery of ABA services to clients";
export const EXPERIENCE_HRS_HELP =
  "Number of total hours boo! These included both restricted and unrestricted hours \n\r\n\r#exactCount \n\r\n\r#PermanentProduct";
export const INDEPENDENT_HELP =
  "Who’s supervising you? \n\r\n\rIndividual: It’s just you and your single supervisor present";
export const GROUP_HELP =
  "Who’s supervising you? \n\r\n\rGroup: A supervisor is there but there are other trainees present but no more than 10- that won’t count boo!";
export const METHOD_OF_SUP_HELP =
  "Did you see your supervisor in person or on video? #IRL #Video \n\r\n\rBeware, phone calls don’t count in this category.";
export const SUP_START_HELP =
  "It’s specific to when the supervisor started. For instance if your experience starts at 9 AM and goes until 2 pm…. But you have supervision from 12 PM to 1 pm. This is where you’d log the time that was specific to just supervision";
export const SUP_END_HELP =
  "It’s specific to when the supervisor started. For instance if your experience starts at 9 AM and goes until 2 pm…. But you have supervision from 12 PM to 1 pm. This is where you’d log the time that was specific to just supervision";
export const SUPERVISED_HELP =
  "Hours accrued when a supervisor is present. These may include restricted or unrestricted activities observed by a supervisor or individual or group meetings with the supervisor.";
export const INDEPENDENT_HOURS_HELP =
  "How many hours did you spend #SOLO without your supervisor?";
export const OBSERVATION_HELP =
  "How many hours did your supervisor observe you with a client?";
export const SUPERVISION_CONTACT_HELP =
  "If your supervisor was present then this counts towards you supervision contact per supervisory period (4 contacts needed for supervised, 6 contacts needed concentrated). : A real-time interaction between the supervisor and trainee that takes place in an individual or group format.";
//export const RESTRICTED_HELP = ""
export const TASK_LIST_ITEM_HELP =
  "Pull out your Cooper book and Task List and check that sh*t off \n\r\n\r#negaitivereinforcemet";
export const SUMMARY_HELP =
  "Prompts: \n\r\n\rTell me a bit about your session, who all was present? # environment \n\r\n\rTell me about your antecedents boo! What areas did you target to cover? What was the plan going into supervision? \n\r\n\rTell me about your behavior. What did you do EXACTLY be #technological \n\r\n\rTell me about your consequences and keep it behavioral babe! Did you meet any goals? Are there areas that still need to be addressed?";

export const SUMMARY_HELP_ARRAY = [
  "Tell me a bit about your session who all was present? # environment",
  "Tell me about your antecedents boo! What areas did you target to cover? What was the plan going into supervision?",
  "Tell me about your behavior. What did you do EXACTLY be #technological",
  "Tell me about your consequences and keep it behavioral babe! Did you meet any goals? Are there areas that still need to be addressed?",
];

export const TASK_LIST_GRID_LEGEND_HELP =
  "#fluency Did you know the more you practice each task list topic, utilizing behavior skills training, you’ll become competent. We think 10 opportunities within each topics the gold ticket. Work smarter not harder babe. And use our proprietary grid to watch your hard work unfold!";

export const HELPTEXT =
  "Duis elit, tempor in id. Massa quis id nec tincidunt consectetur eu ultricies cursus donec.";
