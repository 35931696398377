// import React from 'react';
import { useState } from "react";
import { faqData } from "./faq-data";
import "./faq.scss";

const Faq = () => {
  const [showAns, setShowAns] = useState(-1);

  const handleExpand = (index) => {
    if (showAns === index) {
      setShowAns(-1);
    } else {
      setShowAns(index);
    }
  };

  return (
    <div className="faq">
      <div className="faqHeading">
        <h3>Frequently Asked Questions</h3>
        <p>Everything you need to know about the product and services.</p>
      </div>

      <ul>
        {faqData.map((el, index) => (
          <li key={index} className={showAns === index && "active"}>
            <a
              onClick={() => handleExpand(index)}
              className={showAns === index && "active"}
            >
              {el.question}
            </a>

            {showAns === index && <p>{el.answer}</p>}
            <button onClick={() => handleExpand(index)}>
              {showAns === index ? "-" : "+"}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Faq;
