import React from "react";
import style from "./Confirmation.module.scss";

const Confirmation = ({ setModalVisible }) => {
  return (
    <div className={style.confirmationBlock}>
      <h2>Keep up the good work boo!</h2>
      <p>Track those hours!</p>
      <button
        className="button  is-rounded add-supervisor-btn"
        onClick={() => setModalVisible(false)}
      >
        Track Hours
      </button>
    </div>
  );
};
export default Confirmation;
