import React, { useEffect } from "react";

import MonthPicker from "./month-picker";
import DateButton from "./date-button";
import { getDatesOfMonth } from "./util";

import "./styles.scss";
import moment from "moment";

const MonthlyCalendar = React.forwardRef(
  (
    {
      onChange,
      selected = new Date(),
      minDate = new Date(1900, 0, 1),
      maxDate,
      workdays = [],
      statusDates = [],
      className,
      monthDate= new Date(),
      setMonthDate,
      selectedDate,
      setSelectedDate,
      ...props
    },
    ref
  ) => {
    const minDateVal = minDate.getTime();
    const maxDateVal =
      typeof maxDate === "undefined" ? Number.POSITIVE_INFINITY : maxDate.getTime();
      const getFirstDayOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1);
  
      const today = new Date()

      // const nextMonth = React.useCallback(
      //   () =>
      //     setMonthDate((d) => {
      //       const m = d.getMonth();
      //       const y = d.getFullYear();
      //       if (m === 11) {
      //         return new Date(y + 1, 0);
      //       } else {
      //         return new Date(y, m + 1);
      //       }
      //     }),
      //   []
      // );
    
    const nextMonth = React.useCallback(
      () =>
        setMonthDate((d) => {
          const m = d.getMonth();
          const y = d.getFullYear();
          const newDate = m === 0 ? new Date(y + 1, 11) : new Date(y, m + 1);
          if (newDate.getMonth() === today.getMonth() && newDate.getFullYear() === today.getFullYear()) {
            setSelectedDate(today);
            onChange?.(today);
          } else {
            setSelectedDate(getFirstDayOfMonth(newDate));
            onChange?.(getFirstDayOfMonth(newDate));
          }
         return newDate
    
        }),
        [setMonthDate, setSelectedDate, onChange, today]
    );

    // const prevMonth = React.useCallback(
    //   () =>
    //     setMonthDate((d) => {
    //       const m = d.getMonth();
    //       const y = d.getFullYear();
    //       if (m === 0) {
    //         return new Date(y - 1, 11);
    //       } else {
    //         return new Date(y, m - 1);
    //       }
    //     }),
    //   []
    // );

    const prevMonth = React.useCallback(
      () =>
        setMonthDate((d) => {
          const m = d.getMonth();
          const y = d.getFullYear();
          const newDate = m === 0 ? new Date(y - 1, 11) : new Date(y, m - 1);
          if (newDate.getMonth() === today.getMonth() && newDate.getFullYear() === today.getFullYear()) {
            setSelectedDate(today);
            onChange?.(today);
          } else {
            setSelectedDate(getFirstDayOfMonth(newDate));
            onChange?.(getFirstDayOfMonth(newDate));
          }
         return newDate
        }),
      [setMonthDate, setSelectedDate, onChange, today]
    );
   console.log(monthDate,"monthDate");
   
    const setNewSelectedDate = React.useCallback(
      (date) => {
        setSelectedDate(date);
        onChange?.(date);
      },
      [onChange, setSelectedDate]
    );

    if (selected.getTime() > maxDateVal || selected.getTime() < minDateVal) {
      console.warn(
        "DatePicker: Selected date must fall in the range of maxDate and minDate"
      );
    }

    return (
      <div
        className={`sdp ${className ?? ""}`}
        aria-label="Date Picker"
        tabIndex={0}
        ref={ref}
        {...props}
      >
        <MonthPicker
          month={monthDate?.getMonth()}
          year={monthDate?.getFullYear()}
          nextMonth={nextMonth}
          prevMonth={prevMonth}
        />
        <div className="sdp--dates-grid">
          <p className="sdp--text sdp--text__inactive">Sun</p>
          <p className="sdp--text sdp--text__inactive">Mon</p>
          <p className="sdp--text sdp--text__inactive">Tue</p>
          <p className="sdp--text sdp--text__inactive">Wed</p>
          <p className="sdp--text sdp--text__inactive">Thu</p>
          <p className="sdp--text sdp--text__inactive">Fri</p>
          <p className="sdp--text sdp--text__inactive">Sat</p>

          {getDatesOfMonth(monthDate).map(({ d, active }, i) => {
            const dVal = d.getTime();
            return (
              <DateButton
                key={dVal}
                date={d}
                active={dVal >= minDateVal && dVal <= maxDateVal && active}
                selected={selectedDate.toDateString() === d.toDateString()}
                onClick={setNewSelectedDate}
                workday={(workdays || []).includes(
                  moment(d).format("YYYY-MM-DD")
                )}
                statusDates={statusDates}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

MonthlyCalendar.displayName = "DatePicker";

export default MonthlyCalendar;
