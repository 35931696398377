import moment from "moment";
import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useUser } from "../../../redux/reducers/AuthReducer";
import {
  useAccruedData,
  useSkills,
  useTasks,
  useSuccess as monthlyDocSuccess,
} from "../../../redux/reducers/MonthlyDocReducer";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import {
  useData,
  useIsLoading,
  useSuccess,
} from "../../../redux/reducers/TraineesReducer";
import {
  fetchAccrued,
  fetchAccruedByDate,
} from "../../../redux/sagas/monthlyDocument/fetchAccruedSaga";
import { fetchSkills } from "../../../redux/sagas/monthlyDocument/fetchSkillsSaga";
import { fetchTasks } from "../../../redux/sagas/monthlyDocument/fetchTasksSaga";
import { submitDocument } from "../../../redux/sagas/monthlyDocument/submitDocSaga";
import { fetchAllTrainees } from "../../../redux/sagas/trainees/fetchAllSaga";
import { calculateWeeks, useDispatchEffect } from "../../../utils/hooks";
import "./styles.scss";

import { fetchAll } from "../../../redux/sagas/experienceLog/fetchAllSaga";
import { fetchDoc } from "../../../redux/sagas/monthlyDocument/fetchDocSaga";
import { LABELS, LOG_DATA, TASK_LIST_GRID_LEGEND_HELP } from "../../../utils/CONSTANTS";
import Tooltip from "../../../components/Tooltip";
import { FaEye } from "react-icons/fa";
import { BiCalendar } from "react-icons/bi";
import { useDocs } from "../../../redux/reducers/MonthlyDocReducer";
import { Link, useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import { useRef } from "react";
import {
  useFlagged,
  useFormData,
  useIsExpLoading,
  useLogs, usePastFlagged
} from "../../../redux/reducers/ExpLogReducer";

const TASKS_TABLE = [
  [
    {
      id: "A-1",
      description: "Identify the goals of behavior analysis as a science (i.e., description, prediction, control).",
    },
    {
      id: "B-1",
      description: "Define and provide examples of behavior, response, and response class.",
    },
    {
      id: "B-8",
      description: "Define and provide examples of unconditioned, conditioned, and generalized reinforcers and punishers.",
    },
    {
      id: "C-1",
      description: "Establish operational definitions of behavior.",
    },
    {
      id: "D-1",
      description: "Distinguish between dependent and independent variables.",
    },
    {
      id: "E-1",
      description: "Introduction",
    },
    {
      id: "F-1",
      description: "Review records and available data (e.g., educational, medical, historical) at the outset of the case.",
    },
    {
      id: "G-1",
      description: "Use positive and negative reinforcement procedures to strengthen behavior.",
    },
    {
      id: "G-12",
      description: "Use equivalence-based instruction.",
    },
    {
      id: "H-1",
      description: "State intervention goals in observable and measurable terms.",
    },
    {
      id: "I-1",
      description: "State the reasons for using behavior-analytic supervision and the potential risks of ineffective supervision (e.g., poor client outcomes, poor supervisee performance).",
    },
  ],
  [
    {
      id: "A-2",
      description: "Explain the philosophical assumptions underlying the science of behavior analysis (e.g., selectionism, determinism, empiricism, parsimony, pragmatism)",
    },
    {
      id: "B-2",
      description: "Define and provide examples of stimulus and stimulus class.",
    },
    {
      id: "B-9",
      description: "Define and provide examples of operant extinction.",
    },
    {
      id: "C-2",
      description: "Distinguish among direct, indirect, and product measures of behavior.",
    },
    {
      id: "D-2",
      description: "Distinguish between internal and external validity.",
    },
    {
      id: "E-2",
      description: "Responsibility as a Professional",
    },
    {
      id: "F-2",
      description: "Determine the need for behavior-analytic services.",
    },
    {
      id: "G-2",
      description: "Use interventions based on motivating operations and discriminative stimuli.",
    },
    {
      id: "G-13",
      description: "Use the high-probability instructional sequence.",
    },
    {
      id: "H-2",
      description: "Identify potential interventions based on assessment results and the best available scientific evidence.",
    },
    {
      id: "I-2",
      description: "Establish clear performance expectations for the supervisor and supervisee.",
    },
  ],
  [
    {
      id: "A-3",
      description: "Describe and explain behavior from the perspective of radical behaviorism.",
    },
    {
      id: "B-3",
      description: "Define and provide examples of respondent and operant conditioning.",
    },
    {
      id: "B-10",
      description: "Define and provide examples of stimulus control.",
    },
    {
      id: "C-3",
      description: "Measure occurrence (e.g., count, frequency, rate, percentage).",
    },
    {
      id: "D-3",
      description: "Identify the defining features of single-subject experimental designs (e.g., individuals serve as their own controls, repeated measures, prediction, verification, replication).",
    },
    {
      id: "E-3",
      description: "Responsibility in Practice",
    },
    {
      id: "F-3",
      description: "Identify and prioritize socially significant behavior-change goals.",
    },
    {
      id: "G-3",
      description: "Establish and use conditioned reinforcers.",
    },
    {
      id: "G-14",
      description: "Use reinforcement procedures to weaken behavior (e.g., DRA, FCT, DRO, DRL, NCR).",
    },
    {
      id: "H-3",
      description: "Recommend intervention goals and strategies based on such factors as client preferences, supporting environments, risks, constraints, and social validity.",
    },
    {
      id: "I-3",
      description: "Select supervision goals based on an assessment of the supervisee’s skills.",
    },
  ],
  [
    {
      id: "A-4",
      description: "Distinguish among behaviorism, the experimental analysis of behavior, applied behavior analysis, and professional practice guided by the science of behavior analysis.",
    },
    {
      id: "B-4",
      description: "Define and provide examples of positive and negative reinforcement contingencies.",
    },
    {
      id: "B-11",
      description: "Define and provide examples of discrimination, generalization, and maintenance.",
    },
    {
      id: "C-4",
      description: "Measure temporal dimensions of behavior (e.g., duration, latency, interresponse time).",
    },
    {
      id: "D-4",
      description: "Describe the advantages of single-subject experimental designs compared to group designs.",
    },
    {
      id: "E-4",
      description: "Responsibility to Clients and Stakeholders",
    },
    {
      id: "F-4",
      description: "Conduct assessments of relevant skill strengths and deficits.",
    },
    {
      id: "G-4",
      description: "Use stimulus and response prompts and fading (e.g., errorless, most-to-least, least-to-most, prompt delay, stimulus fading).",
    },
    {
      id: "G-15",
      description: "Use extinction.",
    },
    {
      id: "H-4",
      description: "When a target behavior is to be decreased, select an acceptable alternative behavior to be established or increased.",
    },
    {
      id: "I-4",
      description: "Train personnel to competently perform assessment and intervention procedures.",
    },
  ],
  [
    {
      id: "A-5",
      description: "Describe and define the dimensions of applied behavior analysis (Baer, Wolf, & Risley, 1968).",
    },
    {
      id: "B-5",
      description: "Define and provide examples of schedules of reinforcement.",
    },
    {
      id: "B-12",
      description: "Define and provide examples of motivating operations.",
    },
    {
      id: "C-5",
      description: "Measure form and strength of behavior (e.g., topography, magnitude).",
    },
    {
      id: "D-5",
      description: "Use single-subject experimental designs (e.g., reversal, multiple baseline, multielement, changing criterion).",
    },
    {
      id: "E-5",
      description: "Responsibility to Supervisees and Trainees",
    },
    {
      id: "F-5",
      description: "Conduct preference assessments.",
    },
    {
      id: "G-5",
      description: "Use modeling and imitation training.",
    },
    {
      id: "G-16",
      description: "Use positive and negative punishment (e.g., time-out, response cost, overcorrection).",
    },
    {
      id: "H-5",
      description: "Plan for possible unwanted effects when using reinforcement, extinction, and punishment procedures.",
    },
    {
      id: "I-5",
      description: "Use performance monitoring, feedback, and reinforcement systems.",
    },
  ],
  [
    null,
    {
      id: "B-6",
      description: "Define and provide examples of positive and negative punishment contingencies.",
    },
    {
      id: "B-13",
      description: "Define and provide examples of rule-governed and contingency-shaped behavior.",
    },
    {
      id: "C-6",
      description: "Measure trials to criterion.",
    },
    {
      id: "D-6",
      description: "Describe rationales for conducting comparative, component, and parametric analyses.",
    },
    {
      id: "E-6",
      description: "Responsibility in Public Statements",
    },
    {
      id: "F-6",
      description: "Describe the common functions of problem behavior.",
    },
    {
      id: "G-6",
      description: "Use instructions and rules.",
    },
    {
      id: "G-17",
      description: "Use token economies.",
    },
    {
      id: "H-6",
      description: "Monitor client progress and treatment integrity.",
    },
    {
      id: "I-6",
      description: "Use a functional assessment approach (e.g., performance diagnostics) to identify variables affecting personnel performance.",
    },
  ],
  [
    null, 
    {
      id: "B-7",
      description: "Define and provide examples of automatic and socially mediated contingencies.",
    },
    {
      id: "B-14",
      description: "Define and provide examples of the verbal operants.",
    },
    {
      id: "C-7",
      description: "Design and implement sampling procedures (i.e., interval recording, time sampling).",
    },
    null, 
    {
      id: "E-7",
      description: "Responsibility in Research",
    },
    {
      id: "F-7",
      description: "Conduct a descriptive assessment of problem behavior.",
    },
    {
      id: "G-7",
      description: "Use shaping.",
    },
    {
      id: "G-18",
      description: "Use group contingencies.",
    },
    {
      id: "H-7",
      description: "Make data-based decisions about the effectiveness of the intervention and the need for treatment revision.",
    },
    {
      id: "I-7",
      description: "Use function-based strategies to improve personnel performance.",
    },
  ],
  [
    null, 
    null, 
    {
      id: "B-15",
      description: "Define and provide examples of derived stimulus relations.",
    },
    {
      id: "C-8",
      description: "Evaluate the validity and reliability of measurement procedures.",
    }, 
    null, 
    null, 
    {
      id: "F-8",
      description: "Conduct a functional analysis of problem behavior.",
    },
    {
      id: "G-8",
      description: "Use chaining.",
    },
    {
      id: "G-19",
      description: "Use contingency contracting.",
    },
    {
      id: "H-8",
      description: "Make data-based decisions about the need for ongoing services.",
    },
    {
      id: "I-8",
      description: "Evaluate the effects of supervision (e.g., on client outcomes, on supervisee repertoires).",
    },
  ],
  [
    null, 
    null, 
    null, 
    {
      id: "C-9",
      description: "Select a measurement system to obtain representative data given the dimensions of behavior and the logistics of observing and recording.",
    }, 
    null, 
    null, 
    {
      id: "F-9",
      description: "Interpret functional assessment data.",
    },
    {
      id: "G-9",
      description: "Use discrete-trial, free-operant, and naturalistic teaching arrangements.",
    },
    {
      id: "G-20",
      description: "Use self-management strategies.",
    },
    {
      id: "H-9",
      description: "Collaborate with others who support and/or provide services to clients.",
    },
    null
  ],
  [
    null, 
    null, 
    null, 
    {
      id: "C-10",
      description: "Graph data to communicate relevant quantitative relations (e.g., equal-interval graphs, bar graphs, cumulative records).",
    }, 
    null, 
    null, 
    null, 
    {
      id: "G-10",
      description: "Teach simple and conditional discriminations.",
    },
    {
      id: "G-21",
      description: "Use procedures to promote stimulus and response generalization.",
    },
    null, 
    null
  ],
  [
    null, 
    null, 
    null, 
    {
      id: "C-11",
      description: "Interpret graphed data.",
    }, 
    null, 
    null, 
    null, 
    {
      id: "G-11",
      description: "Use Skinner’s analysis to teach verbal behavior.",
    },
    {
      id: "G-22",
      description: "Use procedures to promote maintenance.",
    },
    null, 
    null
  ],
];
const NUMBER_TASKS = [
  {
    id: "0",
    value: 0,
    color: "#EEEEEE",
  },
  {
    id: "1",
    value: 1,
    color: "var(--color-light)",
  },
  {
    id: "2",
    value: 2,
    color: "#F4EEB6",
  },
  {
    id: "3",
    value: 3,
    color: "#E8EDBD",
  },
  {
    id: "4",
    value: 4,
    color: "#DDECC4",
  },
  {
    id: "5",
    value: 5,
    color: "#D1EBCA",
  },
  {
    id: "6",
    value: 6,
    color: "#C5EBD1",
  },
  {
    id: "7",
    value: 7,
    color: "#B9EAD7",
  },
  {
    id: "8",
    value: 8,
    color: "#AEE9DE",
  },
  {
    id: "9+",
    value: 9,
    color: "#A1E8E4",
  },
];

const NUMBER_TASKS2 = [
  {
    id: "0 - 3",
    value: 1,
    textColor: 'black',
    color: "var(--color-warning)",
  },
  {
    id: "3 - 6",
    value: 2,
    textColor: 'white',
    color: "var(--color-info)",
  },
  {
    id: "6 - 10",
    value: 3,
    textColor: 'white',
    color: "var(--color-primary)",
  },
];

const MonthlyDocument = () => {
  const navigate = useNavigate();
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();
  const trainees = useData();
  const success = useSuccess();
  const isLoading = useIsLoading();

  const [date, setDate] = useState(new Date());
  
  const [isChecked, setIsChecked] = useState(false);
  const logs = useLogs();
  const tasks = {};
  let total_restricted_hrs = 0;
  let total_unrestricted_hrs = 0;
  let total_supervisor_hrs = 0;

  const [trainee, setTrainee] = useState();

  useDispatchEffect(fetchAllTrainees, null, true);

  useEffect(() => {
    if (success) setTrainee(trainees[0]?.id);
  }, [success]);


  for (const log of logs) {
    if (log.user.id != trainee) {
      continue;
    }
    const taskCodes = log.task?.map((t) => t.name.split(" ")[0]);
    let check = moment(log.date_of_experience).isSame(date, "month");
    if (isChecked) {
      if (check) {
        for (const taskCode of taskCodes) {
          if (taskCode) {
            if (tasks[taskCode] && Array.isArray(tasks[taskCode]))
              tasks[taskCode].push(log?.date_of_experience);
            else tasks[taskCode] = [log?.date_of_experience];
          }
        }
        total_restricted_hrs += Number(log?.restricted_hours);
        total_unrestricted_hrs += Number(log?.unrestricted_hours);
      }
    } else {
      for (const taskCode of taskCodes) {
        if (taskCode) {
          if (tasks[taskCode] && Array.isArray(tasks[taskCode]))
            tasks[taskCode].push(log?.date_of_experience);
          else tasks[taskCode] = [log?.date_of_experience];
        }
      }
      total_restricted_hrs += Number(log?.restricted_hours);
      total_unrestricted_hrs += Number(log?.unrestricted_hours);
    }
  }

  const [week, setWeek] = useState(0);

  const weeks = calculateWeeks(date);

  const onPrevMonth = () => {
    const newDate = moment(date).subtract(1, "month");
    setDate(newDate.toDate());
  };
  const onNextMonth = () => {
    const newDate = moment(date).add(1, "month");
    setDate(newDate.toDate());
  };
  const onWeekChange = (weekNo) => {
    setWeek(weekNo);
  };

  const toogleButton = () => {
    if (isChecked) {
      return (
        <div onClick={handleOnChange}>
          <FaEye /> View Overall
        </div>
      );
    } else {
      return (
        <div onClick={handleOnChange}>
          <BiCalendar /> View Monthly
        </div>
      );
    }
  };

  const callbackFunction = (childData) => {
    setDate(childData);

    // Refresh experience log after date update
    if (isChecked) {
      refreshData(!isChecked, childData);
    }
  };

  const renderTaskColor = (task) => {
    const taskDates = tasks[task];
    if (taskDates?.length > 0 && taskDates?.length <= 3) return "var(--color-warning)";
    else if (taskDates?.length > 3 && taskDates?.length <= 6) return "var(--color-info)";
    else if (taskDates?.length > 6 && taskDates?.length <= 10) return "var(--color-primary)";
    else return "var(--color-white)";
  };

  const renderTaskDates = (task) => {
    var taskDates = tasks[task];
    console.log(tasks);
    if (!taskDates) {
      return <li>
        <em>Task not completed</em>
      </li>
    }
    taskDates = taskDates.sort();
    return taskDates.map((i) => {
      var date = "mm/dd/yyyy";
      var splitDate = i.split("-");
      if (splitDate[1].startsWith("0")) {
        splitDate[1] = splitDate[1].substring(1);
      }
      if (splitDate[2].startsWith("0")) {
        splitDate[2] = splitDate[2].substring(1);
      }
      date = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];
      const disabled = date === "mm/dd/yyyy";
      return (
        <li key={task + i}>
          {date}
        </li>
      );
    });
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const onRefreshData = useDispatchEffect(fetchAll, null, true);

  // Triggers dispatch effect with date param ('MM-YYYY') or null for overall
  const refreshData = (isOverall, date = null) => {
    if (isOverall) {
      onRefreshData()
    } else {
      const params = moment(date).format('MM-YYYY')
      onRefreshData(params)
    }
  }

  if (isLoading) return <LoadingSpinner style={{ width: "100%" }} />;

  return (
    <div className="section monthly-document" style={{ overflowX: "auto" }}>
      <div className="columns">
        <div className="column is-one-fifths">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          <p className="headingMain">Task List Grid</p>
          <label className="label">Student Name</label>
          <select
            className="select dropdownCustom is-normal"
            value={trainee}
            onChange={(e) => setTrainee(e.target.value)}
          >
            {trainees.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="section2">
        <div className="columns">
          <div className="column">
          <div className="grid-top mb-2" style={{ alignItems: "center" }}>
            <div className="calendar-header">
              <div>
                <div className="topping">{toogleButton()}</div>
              </div>
              <p
                className="headingMain mt30"
                style={{ marginLeft: "12px"}}
              >
                <button
                  className="backlink"
                  onClick={onPrevMonth}
                  disabled={!isChecked}
                >
                  <IoIosArrowBack
                    size={24}
                    style={{ margin: "0 20px", marginLeft: 0 }}
                  />
                </button>
                <select
                  className="select selectBtn dropdownCustom2  is-normal"
                  style={{ height: "28px" }}
                  value={week}
                  onChange={(e) => onWeekChange(e.target.value)}
                >
                  {weeks.map((week, index) => (
                    <option key={index} value={index}>
                      {isChecked
                        ? `${moment()
                            .month(date.getMonth())
                            .format("MMMM")}  ${date.getFullYear()}`
                        : "Overall Progress"}
                    </option>
                  ))}
                </select>
                <button
                  className=" backlink"
                  onClick={onNextMonth}
                  style={{ margin: "0 20px" }}
                  disabled={!isChecked}
                >
                  <IoIosArrowForward size={24} style={{ margin: "0 20px" }} />
                </button>
              </p>
              <div className="grid-top mt30">
                <div>
                  <div style={{float: "left", position: "relative", top: "-5px", left: "-5px" }}>
                    <Tooltip content={TASK_LIST_GRID_LEGEND_HELP} />
                  </div>
                  <div className="color-scale">
                    {NUMBER_TASKS2.map((i) => {
                      return (
                        <div className="pill" style={{ backgroundColor: i.color, color: i.textColor }} key={i.id}>
                          {i.id}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="columns">
              <div className="column is-four-fifths monthlyTableMove">
                <table style={{ border: "none" }}>
                  <tbody className="monthlyTable" style={{ border: "none" }}>
                  {TASKS_TABLE.map((row, i) => (
                    <tr key={"row-" + i}>
                      {row.map((cell, j) => (
                        <td
                          key={`cell-${i}${j}`}
                          className={j === 2 ? "customColumn" : ""}
                        >
                          {cell && (
                            <div
                              style={{
                                background: renderTaskColor(cell.id),
                                border: "none",
                              }}
                              className="selectBtn dropdownCustom mt is-normal"
                            >
                              {cell.id.replace("-", "")}
                              <div>
                                <p
                                style={{
                                  background: renderTaskColor(cell.id),
                                  textAlign: "left",
                                  paddingTop: 10,
                                  paddingLeft: 10,
                                }}>
                                  <span style={{display: "inline-block", marginBottom: "10px"}}>{cell.description}</span><br />
                                    <strong>
                                      Dates completed:
                                    </strong>
                                  </p>
                                <ul
                                  style={{
                                    background: renderTaskColor(cell.id),
                                    textAlign: "left",
                                    padding: 10,
                                  }}
                                >
                                  {renderTaskDates(cell.id)}
                                </ul>
                              </div>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p className="instruct supervisor">Select a task above to view specific logs</p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

const AccruedTable = ({ title, data }) => {
  const {
    independent_hours,
    unrestricted_hours,
    percent_unrestricted,
    supervision_contacts,
    percent_supervision,
  } = data;

  return (
    <div>
      <table className="agenda-table">
        <tr className="barTableHeader">
          <th colSpan="2">{title}</th>
        </tr>
        <tr>
          <td>Independent Hours</td>
          <td className="colSize1">{independent_hours || "0"}</td>
        </tr>
        <tr>
          <td>Unrestricted Hours</td>
          <td className="colSize1">{unrestricted_hours || "0"}</td>
        </tr>
        <tr>
          <td>% Unrestricted</td>
          <td className="colSize1">{percent_unrestricted?.toFixed(0) || "0"}%</td>
        </tr>
        <tr>
          <td># Supervision Contacts</td>
          <td className="colSize1">{supervision_contacts?.toFixed(0) || "0"}</td>
        </tr>
        <tr>
          <td>% Supervision</td>
          <td className="colSize1">{percent_supervision?.toFixed(0) || "0"}%</td>
        </tr>
      </table>
    </div>
  );
};

const Task = ({ name, onChange }) => {
  const [task, setTask] = useState({
    name,
    canDemonstrate: false,
    canExplain: false,
    feedback: "",
    review: "",
  });
  const handleChange = (prop) => (e) => {
    const newTask = { ...task, [prop]: e.target.value };
    setTask(newTask);
  };
  const handleCheckChange = (prop) => (e) => {
    const newTask = { ...task, [prop]: e.target.checked };
    setTask(newTask);
    onChange(newTask);
  };

  return (
    <tr>
      <td style={{ width: "30%", textAlign: "left", paddingLeft: "16px" }}>
        {name || ""}
      </td>
      <td style={{ width: "10%" }}>
        <input
          type="checkbox"
          className=""
          value={task.canDemonstrate || false}
          onChange={handleCheckChange("canDemonstrate")}
        />
      </td>
      <td style={{ width: "10%" }}>
        <input
          type="checkbox"
          className=""
          value={task.canExplain || false}
          onChange={handleCheckChange("canExplain")}
        />
      </td>
      <td style={{ width: "20%" }}>
        <input
          type="text"
          className="inputagenda3"
          value={task.feedback || ""}
          onChange={handleChange("feedback")}
          onBlur={() => onChange(task)}
        />
      </td>
      <td style={{ width: "20%" }}>
        <input
          type="text"
          className="inputagenda3"
          value={task.review || ""}
          onChange={handleChange("review")}
          onBlur={() => onChange(task)}
        />
      </td>
    </tr>
  );
};
export default MonthlyDocument;
