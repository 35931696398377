import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Check from "../assets/check.png";
import { updateCouponDetails } from "../redux/sagas/subscription/paymentSaga";
import ApiService from "../services/ApiService";
import "./CardPayment.scss";
import LocalStorage from "../services/LocalStorage";
import { useIsLoading } from "../redux/reducers/SubscriptionReducer";
const useOptions = () => {
  const options = {
    style: {
      base: {
        iconColor: "#bababa",
        color: "#333",
        backgroundColor: "#FFF",
        margin: "16px",
        fontWeight: "400",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#333",
        },
        "::placeholder": {
          color: "#999",
        },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    },
  };
  return options;
};

const CardPaymentForm = ({ onSuccess, isUpdate = false }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const dispatch = useDispatch();

  const [cardholder, setCardholder] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponDetails, setCouponDetails] = useState(true);
  const [couponValidated, setCouponValidated] = useState(true);
  const [couponLoading, setCouponLoading] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const isLoading = useIsLoading();

  useEffect(() => {
    return () => {
      setLoad(false);
      dispatch(
        updateCouponDetails({
          coupon: {},
        })
      );
    };
  }, []);

  useEffect(() => {
    if (isLoad && isLoading) {
      setLoad(false);
    }
  }, [isLoading]);

  const handleChange = (e) => {
    setCardholder(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (couponCode && !couponValidated) {
      return toast.error(
        "Coupon Entered but not applied. Please clear if not intended"
      );
    }
    setLoad(true);
    const result = await stripe.createToken(
      elements.getElement(CardNumberElement),
      {
        name: cardholder,
      }
    );
    if (result.error) {
      toast.error(result.error.message);
      setLoad(false);
    } else if (result.token) {
      LocalStorage.storeData("loginPopup", true);
      onSuccess({
        token: result.token.id,
        couponCode: couponCode && couponValidated ? couponCode : "",
      });
    }
  };

  const handleRemoveCouponCodeClick = async () => {
    try {
      dispatch(
        updateCouponDetails({
          coupon: "",
        })
      );
      toast.success("Coupon removed successfully!");
      setCouponValidated(false);
    } catch (e) {
      setCouponValidated(true);
      toast.error("Error in removing coupon");
    }
  };

  const handleApplyCouponClick = async () => {
    if (!couponCode.trim()) {
      toast.error("Please enter a coupon code");
      return;
    }
    try {
      setCouponLoading(true);
      const res = await ApiService.checkCouponValidation(couponCode);
      setCouponDetails(res?.data?.coupon);
      if (!res?.data?.coupon?.valid) {
        setCouponValidated(false);
        toast.error("Invalid Coupon");
        setCouponLoading(false);
        return;
      }
      dispatch(
        updateCouponDetails({
          coupon: res?.data?.coupon,
        })
      );
      toast.success(
        `Congratulations you got ${
          res?.data?.coupon?.percent_off
            ? `${res?.data?.coupon?.percent_off}%`
            : `$${res?.data?.coupon?.amount_off / 100}`
        } off!`
      );
      setCouponValidated(true);
      setCouponLoading(false);
    } catch (e) {
      setCouponValidated(false);
      setCouponLoading(false);

      toast.error("Invalid Coupon");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="columns ">
        <div className="column">
          <label className="label ml0">Card number</label>
          <div className="input-card">
            <CardNumberElement options={options} />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <label className="label ml0">Expiration date</label>
              <div className="input-card">
                <CardExpiryElement options={options} />
              </div>
            </div>
            <div
              className="ml10"
              style={{ display: "flex", flexDirection: "column", flex: 1 }}
            >
              <label className="label ml0">CVV</label>
              <div className="input-card">
                <CardCvcElement options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns ">
        <div className="column ">
          <label className="label ml0">Card holder name</label>
          <input
            className="input has-input-background"
            type="text"
            name="cardholderName"
            value={cardholder}
            onChange={handleChange}
          />
        </div>
      </div>
      {!isUpdate && (
        <div className="columns ">
          <div className="column">
            <label className="label coupon-label ml0">
              Coupon Code
            </label>
            {couponCode && couponValidated ? (
              <div
                className="column"
                style={{
                  marginTop: "6px",
                  display: "inline-flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "12px 15px",
                  border: "1px solid #1aad58",
                  borderRadius: "5px",
                }}
              >
                <img src={Check} alt="check" style={{ width: "24px" }} />
                <label style={{ fontWeight: 700 }}>
                  {couponCode}
                  <span
                    style={{
                      fontWeight: 400,
                      color: "#1aad58",
                      marginLeft: "5px",
                    }}
                  >
                    applied
                  </span>
                </label>
                <a
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    textDecoration: "none",
                    marginLeft: "40px",
                  }}
                  onClick={handleRemoveCouponCodeClick}
                  type="button"
                >
                  Remove
                </a>
              </div>
            ) : (
              <div className="columns" style={{ alignItems: "flex-end" }}>
                <div className="column">
                  <input
                    className="input has-input-background"
                    type="text"
                    name="couponCode"
                    value={couponCode}
                    onChange={(e) => {
                      setCouponValidated(false);
                      setCouponCode(e.target.value);
                    }}
                  />
                </div>
                <div className="column">
                  <button
                    style={{ textAlign: "center" }}
                    className="button is-rounded dashBtn btnself apply-btn"
                    onClick={handleApplyCouponClick}
                    type="button"
                    disabled={couponLoading}
                  >
                    Apply Coupon
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <br />
      <br />
      <div className="columns is-centered ">
        <div className="column"></div>
      </div>

      <button
        style={{ textAlign: "center" }}
        className="button is-large is-rounded dashBtn btnself"
        type="submit"
        disabled={
          (couponCode && !couponValidated) || !stripe || isLoad || isLoading
        }
      >
        {!isUpdate ? "Continue" : "Update"}
      </button>
    </form>
  );
};

export default CardPaymentForm;
