import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/title.png";
import "./registration.scss";
import { signUpAction } from "../../redux/sagas/auth/AuthSagas";
import { useIsLoading, useUser } from "../../redux/reducers/AuthReducer";
import { useEffect, useState } from "react";
import {
  validateStudentRegistration,
  validateSupervisorRegistration,
} from "../../utils/validation";
import { PRIVACY, TERMS } from "../../utils/CONSTANTS";
import FullScreenDialog from "../../components/FullScreenDialog";
import LoadingButton from "../../components/LoadingButton";
import { useLocation } from "react-router-dom";

const Register = () => {
  const { role } = useParams();
  return (
    <section className="section has-left-background registration">
      <div className="columns is-centered">
        <div className="column"></div>
        <div className="column ">
          <div
            className={`columns is-centered ${
              role === "student" && "register-form-layout"
            }`}
          >
            <div className="column">
              <div className="columns logo-col is-centered">
                <div className="column">
                  <figure className="image register-image">
                    <img
                      src={logo}
                      alt="Tracker! Fieldwork documentation logo"
                    />
                  </figure>
                </div>
              </div>
              {role === "student" && (
                <div className="columns is-centered">
                  <div className="column">
                    <div className="offer-banner">
                      <div className="offer-banner-inner">
                        <h4>
                          <span>Sign up</span> now
                        </h4>
                        <p>
                          and get <strong>7</strong>-day free trial
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="columns is-mobile is-centered">
                <div className="column">
                  <h1 className="headingMain">Create Account</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {role === "supervisor" && <SupervisorForm />}
      {role === "student" && <StudentForm />}
    </section>
  );
};

const StudentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useUser();
  const isLoading = useIsLoading();

  useEffect(() => {
    if (user) {
      formik?.resetForm();
      navigate(`/verify-email`);
    }
  }, [user]);

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    check: false,
  };

  const onSubmit = (values, onSubmitProps) => {
    const registerDataSubmit = {
      name: values.name,
      email: values.email,
      password: values.password,
      is_supervisor: false,
    };

    dispatch(signUpAction(registerDataSubmit));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate: validateStudentRegistration,
  });

  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  return (
    <div className="columns is-centered registration">
      <div className="column "></div>
      <div className="column">
        {/* {isLoading ? <Loader /> : ''} */}
        <form onSubmit={formik.handleSubmit}>
          <div className="columns register-form-layout">
            <div className="column ">
              <label className="label">Full Name</label>
              <input
                className="input has-input-background"
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="Register__error">
                {(formik.touched.name && formik.errors.name) || ""}
              </span>
            </div>
          </div>
          <div className="columns register-form-layout">
            <div className="column ">
              <label className="label">Email</label>
              <input
                className="input has-input-background"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="Register__error">
                {(formik.touched.email && formik.errors.email) || ""}
              </span>
            </div>
          </div>
          <div className="columns register-form-layout">
            <div className="column ">
              <label className="label">Password</label>
              <input
                className="input has-input-background"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="Register__error">
                {(formik.touched.password && formik.errors.password) || ""}
              </span>
            </div>
          </div>
          <div className="columns register-form-layout">
            <div className="column ">
              <label className="label">Confirm Password</label>
              <input
                className="input has-input-background"
                type="password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="Register__error">
                {(formik.touched.confirmPassword &&
                  formik.errors.confirmPassword) ||
                  ""}
              </span>
            </div>
          </div>
          <div className="columns register-form-layout">
            <div className="column ">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="check"
                  onClick={() =>
                    formik.setFieldValue("check", !formik.values.check)
                  }
                  className="terms-checkbox"
                />
                <span
                  className={`${
                    formik.touched.check &&
                    formik.errors.check &&
                    "Register__error"
                  } register-checkbox-text`}
                >
                  I agree to the{" "}
                  <a onClick={() => setOpenTerms(true)}>Terms and Conditions</a>{" "}
                  and <a onClick={() => setOpenPrivacy(true)}>Privacy Policy</a>
                </span>
              </label>
            </div>
          </div>
          <div className="columns register-form-layout">
            <div className="column ">
              <LoadingButton
                className="button is-large signin-button"
                loading={isLoading}
                disabled={!formik.isValid}
              >
                Sign Up
              </LoadingButton>
            </div>
          </div>
        </form>
        <div className="columns register-form-layout mt15">
          <div className="column ">
            <div className="bottom-text-div mt-3">
              <a
                className="signup-text"
                onClick={(_) => navigate(`/login/student`)}
              >
                Already have an account? <strong>Sign In</strong>
              </a>
            </div>
          </div>
        </div>
      </div>

      <FullScreenDialog
        open={openTerms}
        handleClose={() => setOpenTerms(false)}
        title={TERMS}
      />
      <FullScreenDialog
        open={openPrivacy}
        handleClose={() => setOpenPrivacy(false)}
        title={PRIVACY}
      />
    </div>
  );
};

const SupervisorForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useUser();
  const isLoading = useIsLoading();

  const query = new URLSearchParams(useLocation().search);
  const supervisorEmail = query.get("email") != null ? query.get("email") : "";

  useEffect(() => {
    if (user) {
      formik?.resetForm();
      navigate(`/verify-email`);
    }
  }, [user]);

  const initialValues = {
    name: "",
    email: supervisorEmail || "",
    password: "",
    confirmPassword: "",
    check: false,
    bacb_account_number: "",
    bacb_certification_number: "",
    supervisor_qualification: "",
    date_qualified: "",
    date_contract: "",
  };

  const onSubmit = (values, onSubmitProps) => {
    const registerDataSubmit = {
      name: values.name,
      email: values.email,
      password: values.password,
      is_supervisor: true,
      bacb_account_number: values.bacb_account_number,
      bacb_certification_number: values.bacb_certification_number,
      supervisor_qualification: values.supervisor_qualification,
      date_qualified: values.date_qualified,
      date_contract: values.date_contract,
    };

    dispatch(signUpAction(registerDataSubmit));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate: validateSupervisorRegistration,
  });

  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="columns is-centered registration">
          <div className="column"></div>
          <div className="column">
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">Full Name</label>
                <input
                  className="input has-input-background"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="Register__error">
                  {(formik.touched.name && formik.errors.name) || ""}
                </span>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">Email</label>
                <input
                  className="input has-input-background"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={supervisorEmail.length > 0}
                />
                <span className="Register__error">
                  {(formik.touched.email && formik.errors.email) || ""}
                </span>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">Password</label>
                <input
                  className="input has-input-background"
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoFocus={false}
                />
                <span className="Register__error">
                  {(formik.touched.password && formik.errors.password) || ""}
                </span>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">Confirm Password</label>
                <input
                  className="input has-input-background"
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="Register__error">
                  {(formik.touched.confirmPassword &&
                    formik.errors.confirmPassword) ||
                    ""}
                </span>
              </div>
            </div>
          </div>
          <div className="column">
            {/* {isLoading ? <Loader /> : ''} */}
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">BACB Account ID Number </label>
                <input
                  className="input has-input-background text-input"
                  type={"text"}
                  name="bacb_account_number"
                  value={formik.values.bacb_account_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="BACBXXXXXX"
                />
                <span className="Register__error">
                  {(formik.touched.bacb_account_number &&
                    formik.errors.bacb_account_number) ||
                    ""}
                </span>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">BACB Certification ID Number</label>
                <input
                  className="input has-input-background text-input"
                  type={"text"}
                  name="bacb_certification_number"
                  value={formik.values.bacb_certification_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="X-XX-XXXXX"
                />
                <span className="Register__error">
                  {(formik.touched.bacb_certification_number &&
                    formik.errors.bacb_certification_number) ||
                    ""}
                </span>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">Supervisor Qualification</label>
                <input
                  className="input has-input-background"
                  type="text"
                  name="supervisor_qualification"
                  value={formik.values.supervisor_qualification}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="Register__error">
                  {(formik.touched.supervisor_qualification &&
                    formik.errors.supervisor_qualification) ||
                    ""}
                </span>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">Date Qualified to Supervise</label>
                <input
                  className="input has-input-background"
                  type="date"
                  name="date_qualified"
                  value={formik.values.date_qualified}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="Register__error">
                  {(formik.touched.date_qualified &&
                    formik.errors.date_qualified) ||
                    ""}
                </span>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <label className="label">Date of Supervision Contract</label>
                <input
                  className="input has-input-background"
                  type="date"
                  name="date_contract"
                  value={formik.values.date_contract}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <span className="Register__error">
                  {(formik.touched.date_contract &&
                    formik.errors.date_contract) ||
                    ""}
                </span>
              </div>
            </div>
            <div className="columns register-terms-layout2">
              <div className="column">
                <label className="checkbox2">
                  <input
                    type="checkbox"
                    name="check"
                    onClick={() =>
                      formik.setFieldValue("check", !formik.values.check)
                    }
                  />
                </label>

                <span
                  className={`${
                    formik.touched.check &&
                    formik.errors.check &&
                    "Register__error"
                  } register-checkbox-text`}
                >
                  I agree to the{" "}
                  <a onClick={() => setOpenTerms(true)}>Terms and Conditions</a>{" "}
                  and <a onClick={() => setOpenPrivacy(true)}>Privacy Policy</a>
                </span>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <LoadingButton
                  className="button is-large signin-button"
                  loading={isLoading}
                  disabled={!formik.isValid}
                >
                  Sign Up
                </LoadingButton>
              </div>
            </div>
            <div className="columns register-form-layout2">
              <div className="column ">
                <div className="bottom-text-div">
                  <a
                    className="signup-text supervisor-signup-text"
                    onClick={(_) => navigate(`/login/supervisor`)}
                  >
                    Already have an account? <strong>Sign In</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <FullScreenDialog
        open={openTerms}
        handleClose={() => setOpenTerms(false)}
        title={TERMS}
      />
      <FullScreenDialog
        open={openPrivacy}
        handleClose={() => setOpenPrivacy(false)}
        title={PRIVACY}
      />
    </>
  );
};
export default Register;
