import moment from "moment";
import React, { useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import Rec1 from "../../../assets/Rectangle 22.png";
import Rec2 from "../../../assets/Rectangle 23.png";
import Rec3 from "../../../assets/Rectangle 24.png";
import {
  useCouponDetails,
  useError,
  useIsLoading,
  useUserPaymentDetails,
  useUserSubscription,
} from "../../../redux/reducers/SubscriptionReducer";
import { fetchUserSubscription } from "../../../redux/sagas/subscription/fetchSaga";
import {
  deleteSubscription,
  updatePaymentSuccess,
} from "../../../redux/sagas/subscription/paymentSaga";
import { useDispatchEffect } from "../../../utils/hooks";
import "./dashboardSubscription.scss";
import swal from "sweetalert";
import { deleteFile } from "../../../redux/sagas/recordFile/deleteRecordFileSaga";
import { fetchUserPaymentDetails } from "../../../redux/sagas/subscription/fetchUserPaymentDetailsSaga";

const DashboardSubscription = () => {
  const navigate = useNavigate();
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();

  const userSubscription = useUserSubscription();
  const userPaymentDetails = useUserPaymentDetails();
  const couponDetails = useCouponDetails();
  const isLoading = useIsLoading();
  const error = useError();

  useEffect(() => {
    if (error) {
      toast.error(error);
      if (error === "Not subscribed yet.")
        navigate(`/student/subscription-screen-dashbard`);
    }
  }, [error]);

  useDispatchEffect(fetchUserSubscription, null, true);
  useDispatchEffect(fetchUserPaymentDetails, null, true);

  let remaining,
    expiration,
    subscription,
    toBePaid = 0;

  if (userSubscription) {
    subscription = userSubscription.subscription;
    console.log("User Subscription:", userSubscription);
    console.log("Subscription", subscription);
    expiration = moment(userSubscription.expiry?.replace("Z", "")).format(
      "MMMM D, YYYY"
    );
    remaining =
      moment(userSubscription.expiry?.replace("Z", "")).diff(
        moment(),
        "hours"
      ) / 24;
    remaining = Math.round(remaining);
  }

  const confirmCancelSub = () => {
    swal({
      title: "Are you sure you want to unsubscribe?",
      text: "If you unsubscribe, you still can access your account until your subscription expires, after which you will not be able to access your uploaded documents.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          deleteSubscription({
            id: userSubscription.id,
          })
        );
        navigate("/student/dashboard-subscription");
      }
    });
  };

  return (
    <div className="section has-light-background">
      <div className="columns">
        <div className="column"></div>
      </div>
      <div className="columns">
        <div className="column max-width-449">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          <h1 className="headingMain" style={{ margin: 0 }}>
            Subscription
          </h1>
          <p className="pera mt30">
            Number of days remaining on your subscription:
          </p>
          {userSubscription?.is_lifetime ? (
            <div className="headingInfinity mt30">
              <span>&#8734;</span>
            </div>
          ) : (
            <p
              className="heading2nd mt20"
              style={{ fontWeight: 800, fontSize: 40 }}
            >
              {remaining ? `${remaining} days` : "--"}
            </p>
          )}
          <hr className="hr-primary" />
          {userSubscription?.is_active ? (
            <div>
              <h3 className="subscription__dashboard--h3">
                Subscription Details
              </h3>
              <p className="pera">
                Current plan: {userSubscription?.subscription?.name}
              </p>

              {userSubscription?.is_lifetime && (
                <p className="heading2nd text-primary mt20">
                  ${subscription?.price?.price?.toFixed(2)}
                </p>
              )}

              {!userSubscription?.is_lifetime && (
                <>
                  <p className="heading2nd text-primary mt20">
                    ${subscription?.price?.price?.toFixed(2)}
                    <span style={{ fontWeight: 600, fontSize: 24 }}>
                      {" "}
                      / {subscription?.price?.period.toLowerCase()}
                    </span>
                  </p>
                  <p className="pera mt-2">
                    Next payment will be on{" "}
                    {moment(userSubscription?.expiry).format("MMM D, YYYY")}
                  </p>
                  <a
                    className=" changelink "
                    onClick={(_) => {
                      dispatch(
                        updatePaymentSuccess({
                          status: false,
                        })
                      );
                      setTimeout(() => {
                        navigate(`/student/subscription-screen-dashbard`);
                      }, 0);
                    }}
                  >
                    Change subscription plan
                  </a>
                </>
              )}
            </div>
          ) : (
            <>
              <h3 className="subscription__dashboard--h3">No Active Plan.</h3>
              <a
                className=" changelink "
                onClick={(_) => {
                  dispatch(
                    updatePaymentSuccess({
                      status: false,
                    })
                  );
                  setTimeout(() => {
                    navigate(`/student/subscription-screen-dashbard`);
                  }, 0);
                }}
              >
                Choose subscription plan
              </a>
            </>
          )}
          <div>
            <hr className="hr-primary" />
          </div>

          {!userSubscription?.is_lifetime &&
            userPaymentDetails &&
            userSubscription?.is_active && (
              <div>
                <span className="mt60">
                  <h3 className="subscription__dashboard--h3">
                    Payment Information
                  </h3>
                  <p>
                    Payments going to card number ending with{" "}
                    {userPaymentDetails?.last4}
                  </p>

                  <a
                    className=" changelink "
                    onClick={(_) => {
                      dispatch(
                        updatePaymentSuccess({
                          status: false,
                        })
                      );
                      setTimeout(() => {
                        navigate(`/student/subscription/update-payment-method`);
                      }, 0);
                    }}
                  >
                    Update payment method
                  </a>
                </span>
                <div>
                  <hr className="hr-primary" />
                </div>
              </div>
            )}
          {!userSubscription?.is_lifetime && userSubscription?.is_active && (
            <div>
              <a className=" cancelLink " onClick={() => confirmCancelSub()}>
                Cancel Subscription
              </a>
              <p className="pera mt-2">
                You will lose access to all of your data and uploads when you
                cancel your subscription
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardSubscription;
